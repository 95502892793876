import { Routes, Route, BrowserRouter } from "react-router-dom";
import React, { Suspense } from "react";

const Layout = React.lazy(() => import("./utils/AppLayout"));
const Home = React.lazy(() => import("./pages/Home"));

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route
            index
            element={
              <Suspense fallback={<h6>Loading...</h6>}>
                <Home />
              </Suspense>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
